<template>
  <v-row>
    <v-col cols="12" class="col-sm-2">
      <v-card outlined class="pa-4 c-pointer d-flex align-center fill-width justify-space-between" @click.prevent="filtered = 1">
        All Data
        <v-icon v-if="filtered === 1">
          mdi-chevron-right
        </v-icon>
      </v-card>
      <v-card outlined class="pa-4 c-pointer d-flex align-center fill-width justify-space-between" @click.prevent="filtered = 2">
        PAID
        <v-icon v-if="filtered === 2">
          mdi-chevron-right
        </v-icon>
      </v-card>
      <v-card outlined class="pa-4 c-pointer d-flex align-center fill-width justify-space-between" @click.prevent="filtered = 3">
        UNPAID
        <v-icon v-if="filtered === 3">
          mdi-chevron-right
        </v-icon>
      </v-card>
      <v-divider class="my-4" />
      <v-card outlined class="pa-4 c-pointer d-flex align-center fill-width justify-space-between">
        <xlsx-workbook name="vendor-transport">
          <xlsx-sheet
            :sheet-name="'VENDOR_TRANSPORT'"
            :collection="sheetAll"
          />
          <!-- <xlsx-sheet
            :sheet-name="'VENDOR'"
            :collection="sheetVendor"
          />
          <xlsx-sheet
            :sheet-name="'TOTAL'"
            :collection="sheetTotal"
          /> -->
          <xlsx-download :filename="'vendor-transport-' + (new Date()).toISOString() + '.xlsx'" :download="''">
            <v-btn depressed block color="primary" class="white--text text-capitalize rounded-pill">
              Export
            </v-btn>
          </xlsx-download>
        </xlsx-workbook>
      </v-card>
    </v-col>
    <v-col cols="12" class="col-sm-10">
      <v-data-table
        :headers="headers"
        :items="table"
        item-key="id"
        :server-items-length="tableTotal"
        :page.sync="tablePage"
        :items-per-page.sync="tableLimit"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 100]
        }"
        :loading="tableLoading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="py-2">
            <!-- <div class="d-flex align-center text-uppercase">
              <v-icon class="mr-1">
                mdi-barcode
              </v-icon>
              <span class="font-weight-bold caption">
                MANIFEST/{{ item.store_origin_code }}/{{ item.store_destination_code }}/{{ item.vendor_code }}/{{ ('000000' + item.id).slice(-6) }}
              </span>
            </div> -->
            <div>
              {{ item.store_origin_name || '-' }} > {{ item.store_destination_name || '-' }}
            </div>
            <div v-if="item.store_origin_city || item.store_origin_city">
              {{ item.store_origin_city || '-' }} > {{ item.store_destination_city || '-' }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.vendor_name`]="{ item }">
          <div class="py-2 text-caption">
            Dispatch : {{ item.dispatch_vehicle_notes }}
            <div>
              Transport : {{ item.transport_schedule_name }}
            </div>
            <div>
              Vendor : {{ item.vendor_name }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <div class="py-2">
            {{ $localDT(item.created, 'datetimedefault') }}
          </div>
        </template>
        <template v-slot:[`item.subtotal`]="{ item }">
          <div class="py-2 text-right text-caption">
            Transport {{ $price(item.transport_schedule_price) }}
            <div>
              Vendor {{ $price(item.vendor_price) }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.price_paid`]="{ item }">
          <div class="d-flex flex-column align-end py-2">
            {{ $price(item.price_paid) }}
            <v-btn v-if="parseInt(item.price_paid) < parseInt(item.price)" color="secondary" x-small depressed class="mt-1" @click.prevent="PaymentGetShow(item)">
              <v-icon x-small class="mr-1">
                mdi-cash
              </v-icon>
              PAY NOW
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <div class="py-2 text-right">
            {{ $price(item.price) }}
          </div>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <div class="py-2 font-weight-bold text-right">
            {{ $price(parseInt(item.price || 0) - parseInt(item.price_paid || 0)) }}
          </div>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-model="dialogPayment.show"
      persistent
      scrollable
      max-width="750"
    >
      <v-card v-if="dialogPayment.selected">
        <v-card-title class="body-1 justify-center text-uppercase font-weight-bold">
          Pembayaran
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4 px-2 px-sm-4" style="max-height: 90vh;">
          <v-row v-if="parseInt(dialogPayment.new.amount || 0) > 0" class="flex-stretch">
            <v-col cols="12" class="col-sm-3 align-center">
              <v-select
                v-model="dialogPayment.new.payment_type"
                :items="[{ name: 'Cash', value: 'cash' }, { name: 'Bank Transfer', value: 'transfer' }, { name: 'Online (Xendit)', value: 'xendit' }]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                label="Tipe Pembayaran"
              />
            </v-col>
            <v-col cols="12" class="col-sm-3 align-center">
              <v-text-field
                v-model.number="dialogPayment.new.amount"
                outlined
                dense
                hide-details
                prefix="Rp"
                label="Amount"
                @keypress="$NumOnly"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 align-center">
              <v-text-field
                v-model="dialogPayment.new.notes"
                outlined
                dense
                hide-details
                label="Notes"
              />
            </v-col>
            <v-col cols="12" class="col-sm-2 d-flex align-center justify-center">
              <v-btn :loading="dialogPayment.loading" depressed color="brown" dark class="text-capitalize" small @click.prevent="PaymentProcess()">
                Add Payment
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="flex-stretch">
            <v-col v-if="dialogPayment.data.length && parseInt(dialogPayment.selected.id)" cols="12" class="pb-3 grey lighten-4">
              <v-card v-for="(p, iP) in dialogPayment.data" :key="'payment-history-' + iP" class="px-3 py-2 my-1 rounded-lg d-flex align-center justify-space-between white" outlined>
                <div class="">
                  <div class="d-flex align-center text-uppercase pr-2">
                    <v-icon class="mr-1">
                      mdi-cash
                    </v-icon>
                    <span class="font-weight-bold caption">
                      [{{ (p.payment_type || '').toUpperCase() }}] {{ p.notes || '-' }}
                    </span>
                    <div class="caption pl-2">
                      {{ $price(p.amount) }}
                    </div>
                  </div>
                  <div class="d-flex align-center caption grey--text text--lighten-1">
                    <v-icon class="mr-1" small color="grey lighten-1">
                      mdi-calendar-clock
                    </v-icon>
                    {{ $localDT(p.created, 'datetime') }}
                  </div>
                </div>
                <v-spacer />
                <v-btn v-if="p.payment_link && p.payment_type === 'xendit'" :href="p.payment_link" target="_blank" small outlined color="primary" class="text-capitalize rounded-pill white--text">
                  Payment Link
                </v-btn>
                <v-chip v-else-if="p.payment_type !== 'xendit'" :color="parseInt(p.status) ? 'green' : 'red'" x-small dark>
                  {{ parseInt(p.status) ? 'Sudah dibayar' : 'Belum dibayar' }}
                </v-chip>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogPayment.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'
export default {
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data: () => ({
    filtered: 1,
    dates: [],
    tableLoading: false,
    headers: [
      {
        text: 'ROUTES',
        value: 'id',
        sortable: false
      },
      { text: 'Vendor/Transport', value: 'vendor_name', sortable: false },
      { text: 'Created', value: 'created', sortable: false },
      { text: 'Paid', value: 'price_paid', sortable: false },
      { text: 'Subtotal', value: 'subtotal', sortable: false },
      { text: 'Total', value: 'price', sortable: false },
      { text: 'Balance', value: 'balance', sortable: false }
    ],
    tableSearch: '',
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {
      sortBy: ['created'],
      sortDesc: 'DESC',
      itemsPerPage: 25,
      page: 1
    },
    dialogPayment: {
      show: false,
      loading: false,
      data: [],
      selected: null,
      new: {
        payment_type: 'cash',
        amount: 0,
        notes: ''
      }
    }
  }),
  computed: {
    sheetAll () {
      return this.table.map(r => {
        const obj = Object.assign({}, r)
        const o = {
          DISPATCH: obj.dispatch_vehicle_notes || '',
          DATE: new Date(obj.created).toISOString(),
          ROUTES_BRANCH: (obj.store_origin_name || '-') + ' TO ' + (obj.store_destination_name || '-'),
          ROUTES_CITY: (obj.store_origin_city || '-') + ' TO ' + (obj.store_destination_city || '-'),
          VENDOR: obj.vendor_name,
          VENDOR_CODE: obj.vendor_code,
          TRANSPORT: obj.dispatch_vehicle_notes,
          VENDOR_PRICE: parseInt(obj.vendor_price) || 0,
          PRICE_TRANSPORT: parseInt(obj.transport_schedule_price) || 0,
          PRICE_TOTAL: parseFloat(obj.price) || 0,
          PAID: parseFloat(obj.price_paid) || 0,
          UNPAID: (parseFloat(obj.price) || 0) - (parseFloat(obj.price_paid) || 0)
        }
        return o
      })
    },
    amountPaid () {
      let paid = 0
      if (this.dialogPayment.show) {
        for (let p = 0; p < this.dialogPayment.data.length; p++) {
          paid += parseInt(this.dialogPayment.data[p].amount) || 0
        }
      }
      return paid
    }
  },
  watch: {
    options () {
      this.DISPATCH_GET()
    },
    filtered () {
      this.DISPATCH_GET()
    }
  },
  mounted () {
    this.DISPATCH_GET()
  },
  methods: {
    DISPATCH_GET () {
      this.tableLoading = true
      const fromTo = this.GetDates(this.dates)
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const limit = (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '')
      const withSortBy = sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : ''
      const query = '?finance=' + this.filtered + (parseInt(page) > 0 ? '&page=' + page : '') + limit + withSortBy
      this.$store.dispatch('logistic/DISPATCH_GET', query + fromTo)
        .then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
            // this.summaryPrice = parseInt(res.data.data.sum) || 0
          } else {
            this.table = []
            this.tableTotal = 0
          }
          this.tableLoading = false
        })
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    },
    PaymentGetShow (item) {
      this.dialogPayment.new.payment_type = 'cash'
      this.dialogPayment.new.notes = ''
      this.$store.dispatch('logistic/PAYMENT_GET', '?trx=' + parseInt(item.id) + '&type=DISPATCH')
        .then((res) => {
          if (res.status) {
            this.dialogPayment.selected = Object.assign({}, item)
            this.dialogPayment.data = res.data.data || []
            this.dialogPayment.new.amount = (parseInt(item.price || 0) - (this.CountAmountPaid(res.data.data) || 0))
            this.dialogPayment.show = true
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    CountAmountPaid (data) {
      let paid = 0
      for (let p = 0; p < data.length; p++) {
        paid += parseInt(data[p].amount) || 0
      }
      return paid
    },
    PaymentProcess () {
      if (!confirm('Buat pembayaran baru ?')) {
        return false
      }
      const data = Object.assign({}, {
        payment_type: this.dialogPayment.new.payment_type,
        amount: this.dialogPayment.new.amount,
        notes: this.dialogPayment.new.notes || '',
        trx_id: this.dialogPayment.selected.id,
        trx_type: 'DISPATCH',
        user: this.dialogPayment.selected.customer
      })
      this.dialogPayment.loading = true
      this.$store.dispatch('logistic/PAYMENT_PROCESS', data)
        .then(() => {
          this.dialogPayment.loading = false
          this.dialogPayment.new.payment_type = ''
          this.PaymentGetShow(this.dialogPayment.selected)
          this.DISPATCH_GET()
        })
    }
  }
}
</script>
